<template>
    <CChartBar
        :datasets="dataSets"
        :labels="['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']"
        :options="options"
    />
</template>

<script>
import {CChartBar} from '@coreui/vue-chartjs'

export default {
    name: 'YearChart',
    components: {CChartBar},
    props: {
        'data': [Array, Object],
    },
    data() {
        return {
            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        fontColor: '#000',
                        boxWidth: 12,
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                            }
                        },
                        stacked: true
                    }]
                }
            },
        }
    },
    computed: {
        dataSets() {
            let outgo = []
            let paid = []
            let unpaid = []

            Object.keys(this.data).forEach((index) => {
                outgo.push(this.data[index]['outgo'])
                paid.push(this.data[index]['paid'])
                unpaid.push(this.data[index]['unpaid'])
            })

            return [
                {
                    label: 'Bezahlt',
                    backgroundColor: '#C2E89B',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: paid,
                    stack: 'Stack 0',
                },
                {
                    label: 'Offen',
                    backgroundColor: '#F4AC65',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: unpaid,
                    stack: 'Stack 0',
                },
                {
                    label: 'Ausgaben',
                    backgroundColor: '#EC9D83',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: outgo,
                    stack: 'Stack 1',
                },
            ]
        }
    }
}
</script>
