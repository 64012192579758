<template>
    <CChartBar
        :datasets="dataSets"
        :labels="labels"
        :options="options"
    />
</template>

<script>
import {CChartBar} from '@coreui/vue-chartjs'

export default {
    name: 'FiveYearChart',
    components: {CChartBar},
    props: {
        'data': [Array, Object],
    },
    data() {
        return {
            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        fontColor: '#000',
                        boxWidth: 12,
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                            }
                        },
                        stacked: true
                    }]
                }
            },
            labels: []
        }
    },
    computed: {
        dataSets() {
            let outgo = []
            let paid = []
            let unpaid = []
            let profit = []
            let labels = []

            Object.keys(this.data).forEach((index) => {
                outgo.push(this.data[index]['outgo'])
                paid.push(this.data[index]['paid'])
                profit.push(this.data[index]['profit'])
                labels.push(index)
            })

            this.labels = labels

            return [
                {
                    label: 'Umsatz',
                    backgroundColor: '#C2E89B',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: paid,
                    stack: 'Stack 0',
                },
                {
                    label: 'Ausgaben',
                    backgroundColor: '#EC9D83',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: outgo,
                    stack: 'Stack 1',
                },
                {
                    label: 'Ertrag',
                    backgroundColor: '#BBE0FB',
                    borderColor: '#000',
                    borderWidth: 1,
                    data: profit,
                    stack: 'Stack 2',
                },
            ]
        }
    }
}
</script>
