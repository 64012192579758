<template>
    <div>
        <div style="/*zoom: 1.332;*/">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <CCard v-if="!loading">
                <CCardBody>
                    <b-row>
                        <b-col cols="6">
                            <h4>{{ $t('dashboard.label.year') }} {{ this.currentYear }}</h4>
                            <YearChart :data="yearData"/>
                        </b-col>
                        <b-col cols="6">
                            <h4>{{ $t('dashboard.label.five_year') }}</h4>
                            <FiveYearChart :data="fiveYearData"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="2" md="4" sm="6" xs="12" class="cards_label">
                            <div>{{ $t('dashboard.label.current') }}</div>
                            <div>{{ $t('dashboard.label.forecast') }}</div>
                        </b-col>
                        <b-col xl="2" md="4" sm="6" xs="12">
                            <plan-card background="#C2E89B"
                                       title="Umsatz"
                                       :value1="thisYearData.paid"
                                       :value2="thisYearData.plan_sales"
                            ></plan-card>
                        </b-col>
                        <b-col xl="2" md="4" sm="6" xs="12">
                            <plan-card background="#F4AC65"
                                       title="Offen"
                                       :value1="thisYearData.unpaid"
                                       :value2="thisYearData.plan_unpaid"
                            ></plan-card>
                        </b-col>
                        <b-col xl="2" md="4" sm="6" xs="12">
                            <plan-card background="#EC9D83"
                                       title="Ausgaben"
                                       :value1="thisYearData.outgo"
                                       :value2="thisYearData.plan_outgo"
                            ></plan-card>
                        </b-col>
                        <b-col xl="2" md="4" sm="6" xs="12">
                            <plan-card background="#dddddd"
                                       title="Umsatzsteuer"
                                       :value1="thisYearData.vat"
                                       :value2="thisYearData.plan_sales_vat"
                            ></plan-card>
                        </b-col>
                        <b-col xl="2" md="4" sm="6" xs="12">
                            <plan-card background="#BBE0FB"
                                       title="EBITDA"
                                       :value1="thisYearData.ebitda"
                                       :value2="thisYearData.plan_ebitda"
                            ></plan-card>
                        </b-col>
                    </b-row>

<!--                    <b-row class="dashboard_tables">-->
<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.last_offerings') }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.offering') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.company') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.customer') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.date') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="lastOffering in lastOfferings" :key="lastOffering.id">-->
<!--                                    <b-td>-->
<!--                                        <a @click.prevent="$root.$children[0].openModal('kva-modal', lastOffering.id)"-->
<!--                                           href="#">{{ lastOffering.number }}-->
<!--                                        </a>-->
<!--                                    </b-td>-->
<!--                                    <b-td>{{ lastOffering.vorname }} {{ lastOffering.nachname }}</b-td>-->
<!--                                    <b-td>{{ lastOffering.name }}</b-td>-->
<!--                                    <b-td>{{ formatDate(lastOffering.created_at) }}</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/sales" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.sales') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->

<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.last_invoices') }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.offering') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.company') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.sum') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.date') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="lastInvoice in lastInvoices" :key="lastInvoice.id">-->
<!--                                    <b-td>-->
<!--                                        <template v-if="lastInvoice.offering">-->
<!--                                            <a @click.prevent="$root.$children[0].openModal('kva-modal', lastInvoice.offering.id)"-->
<!--                                               href="#">{{ lastInvoice.offering.number }}-->
<!--                                            </a>-->
<!--                                        </template>-->
<!--                                    </b-td>-->
<!--                                    <b-td>-->
<!--                                        <template v-if="lastInvoice.offering">-->
<!--                                            {{ lastInvoice.offering.vorname }} {{ lastInvoice.offering.nachname }}-->
<!--                                        </template>-->
<!--                                    </b-td>-->
<!--                                    <b-td>{{ lastInvoice.betrag | priceFormat }} &euro;</b-td>-->
<!--                                    <b-td>{{ formatDate(lastInvoice.rechnungsdatum) }}</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/invoice" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.invoice') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->

<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.top_outcomes') }} {{ this.currentYear }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.post') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.current_sum') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.prev_sum') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="topOutcome in topOutcomes" :key="topOutcome.id">-->
<!--                                    <b-td>{{ topOutcome.rechnungskonto }}</b-td>-->
<!--                                    <b-td>{{ topOutcome.sum | priceFormat }} &euro;</b-td>-->
<!--                                    <b-td>{{ topOutcome.prev_sum | priceFormat }} &euro;</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/incoming-invoice" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.incoming_invoice') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->
<!--                    </b-row>-->

<!--                    <b-row class="dashboard_tables">-->
<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.top_customers') }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.customer') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.current_count') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.current_sum') }}</b-th>-->
<!--                                    <b-th class="left-border">{{ $t('dashboard.column.prev_count') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.prev_sum') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="topCustomer in topCustomers" :key="topCustomer.id">-->
<!--                                    <b-td>{{ topCustomer.agenturname }}</b-td>-->
<!--                                    <b-td>{{ topCustomer.count || 0 }}</b-td>-->
<!--                                    <b-td>{{ topCustomer.sum | priceFormat }} &euro;</b-td>-->
<!--                                    <b-td class="left-border">{{ topCustomer.prev_count || 0 }}</b-td>-->
<!--                                    <b-td>{{ topCustomer.prev_sum | priceFormat }} &euro;</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/customers" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.customers') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->

<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.top_projects') }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.project_number') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.customer') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.offering') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.sum') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="topProject in topProjects" :key="topProject.id">-->
<!--                                    <b-td>{{ topProject.proj_nr }}</b-td>-->
<!--                                    <b-td>{{ topProject.agenturname }}</b-td>-->
<!--                                    <b-td>{{ topProject.project_name }}</b-td>-->
<!--                                    <b-td>{{ topProject.sum | priceFormat }} &euro;</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/customers" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.projects') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->

<!--                        <b-col md="4" xs="12">-->
<!--                            <h4>{{ $t('dashboard.label.top_products') }}</h4>-->
<!--                            <b-table-simple striped>-->
<!--                                <b-thead head-variant="info">-->
<!--                                    <b-th>{{ $t('dashboard.column.product_number') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.name') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.current_count') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.current_sum') }}</b-th>-->
<!--                                    <b-th class="left-border">{{ $t('dashboard.column.prev_count') }}</b-th>-->
<!--                                    <b-th>{{ $t('dashboard.column.prev_sum') }}</b-th>-->
<!--                                </b-thead>-->
<!--                                <tbody>-->
<!--                                <b-tr v-for="topProduct in topProducts" :key="topProduct.id">-->
<!--                                    <b-td>{{ topProduct.project_articleId }}</b-td>-->
<!--                                    <b-td>{{ topProduct.name }}</b-td>-->
<!--                                    <b-td>{{ topProduct.count || 0 }} {{ topProduct.unit }}</b-td>-->
<!--                                    <b-td>{{ topProduct.sum | priceFormat }} &euro;</b-td>-->
<!--                                    <b-td class="left-border">{{ topProduct.old_count || 0 }} {{ topProduct.unit }}-->
<!--                                    </b-td>-->
<!--                                    <b-td>{{ topProduct.old_sum | priceFormat }} &euro;</b-td>-->
<!--                                </b-tr>-->
<!--                                </tbody>-->
<!--                            </b-table-simple>-->
<!--                            <div class="text-right">-->
<!--                                <router-link to="/products" tag="button" class="btn mb-2 btn-primary">-->
<!--                                    {{ $t('dashboard.button.products') }}-->
<!--                                </router-link>-->
<!--                            </div>-->
<!--                        </b-col>-->
<!--                    </b-row>-->

                </CCardBody>
            </CCard>
        </div>
    </div>
</template>

<script>
import YearChart from './charts/YearChart'
import {mapGetters} from 'vuex'
import FiveYearChart from "./charts/FiveYearChart";
import PlanCard from "./charts/PlanCard";
import moment from 'moment'

export default {
    name: 'Dashboard',
    components: {
        PlanCard,
        FiveYearChart,
        YearChart,
    },
    data() {
        return {
            loading: true,
            yearData: [],
            fiveYearData: [],
            thisYearData: [],
            lastOfferings: [],
            lastInvoices: [],
            topOutcomes: [],
            topCustomers: [],
            topProjects: [],
            topProducts: [],
            currentYear: new Date().getFullYear(),
        }
    },
    mounted() {
        if (localStorage.getItem('firstLogin') === 'true') {
            this.$router.push({path: '/settings/general'})
        }
        this.$store.dispatch('Dashboard/fetchChartsData')
            .then(() => {
                this.yearData = this.getChartsData()['one_year_data']
                this.fiveYearData = this.getChartsData()['five_years_data']
                this.thisYearData = this.getChartsData()['this_year_data']
                this.lastOfferings = this.getChartsData()['last_offerings']
                this.lastInvoices = this.getChartsData()['last_invoices']
                this.topOutcomes = this.getChartsData()['top_outcomes']
                this.topCustomers = this.getChartsData()['top_customers']
                this.topProjects = this.getChartsData()['top_projects']
                this.topProducts = this.getChartsData()['top_products']
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        ...mapGetters('Dashboard', ['getChartsData']),
        formatDate(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY');
        },
    }
}
</script>
