<template>
    <CCard>
        <CCardHeader class="text-center" :style="'background:'+background">
            <strong>{{ title }}</strong>
            <div>{{ value1 }}</div>
        </CCardHeader>
        <CCardBody class="text-center">
            {{ value2 }}
        </CCardBody>
    </CCard>
</template>

<script>
export default {
    name: 'PlanCard',
    props: [
        'background',
        'title',
        'value1',
        'value2',
    ],
}
</script>
